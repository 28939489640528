<template>
  <div class="add_teacher">
    <el-card class="box-card">
      <el-form
        ref="addFromRef"
        :model="addFrom"
        :rules="addFromRules"
        label-width="130px"
      >
        <el-form-item label="标题:">
          {{ addFrom.title }}
        </el-form-item>
        <el-form-item label="下单支付等待时间:" prop="info">
          <!-- 输入框 -->
          <!-- <el-input
          maxlength=""
            v-model.number="addFrom.info"
            placeholder="请输入时间"
            style="width: 45%"
          ></el-input> -->
          <el-input-number
            v-model="addFrom.info"
            :step="1"
            :min="1"
            :max="60"
            step-strictly
          ></el-input-number>
          <div>取值1-60</div>
        </el-form-item>

        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <!-- <el-button type="primary" @click="goBack">返回</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { getBaseSet, baseSetTime } from "../../api/administrator.js";
export default {
  data() {
    return {
      addFrom: {
        info: 0,
        title: "",
        // teacherId: 0,
        // teacherName: "",
        // phone: "",
        // headImgUrl: "",
        // details: "",
      },

      addFromRules: {
        info: [{ required: true, message: "请输入下单支付时间", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getBase();
  },
  methods: {
    getBase() {
      getBaseSet({ id: 2 }).then((res) => {
        if (res.data.code == 0) {
          this.addFrom.title = res.data.data.title;
          this.addFrom.info = res.data.data.info;
        }
      });
    },
    submitForm() {
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        if (this.isSubmit) return;
        this.isSubmit = true;
        const { data } = await baseSetTime({ info: this.addFrom.info });
        this.isSubmit = false;
        if (data.code == 0) {
          //   this.goBack();
          this.getBase();
          this.$message.success(data.msg);
        } else {
          this.getBase();
          this.$message.warning(data.msg);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>
